import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Avatar, Button, IconButton, Menu, MenuItem, Toolbar } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import { supabase } from '../../lib/supabaseClient';
import { useAuth } from '../../contexts/Auth';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import logo from '../../images/macavelogo.svg';
import beerAv from '../../images/beer.png';

import style from './Header.module.css'

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    title: {
      justifyContent: "left",
      flexGrow: 1,
      '&:hover': {
        background: 'none',
    },
      
    },
  }));

const Header = () => {

    const history = useHistory();
    const { user, signOut } = useAuth();

    const [avatarUrl, setAvatarUrl] = useState(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
      async function downloadImage() {
        try {
      
            let { data: profiles, error } = await supabase
              .from('profiles')
              .select(`avatar_url`)
              .eq('id', user.id)
              .single()
  
              if (error) throw error
  
              const {data, err} = await supabase.storage.from('avatars').download(profiles.avatar_url)
  
              if (err) throw err
  
              const url = URL.createObjectURL(data)
              setAvatarUrl(url)
  
        } catch (error) {
            console.log('Error downloading image: ', error.message);
        }
    };
      downloadImage()

    }, [user]);


    
    const handleSignOut = async () => {
        let { error } = await signOut()
        history.push("/")
        if (error) console.log(error);
    }

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <AppBar position="static" color="primary">
                <Toolbar>
                    <IconButton className={classes.title} disableRipple>
                      <Link to="/"><img src={logo} alt="logo" className={style.logo} /></Link>
                    </IconButton>
                    
                    <IconButton aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick} className={classes.menu}>
                      <Avatar src={avatarUrl ? avatarUrl : beerAv}/>
                      
                    </IconButton>
                    <Menu
                      id="fade-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={open}
                      onClose={handleClose}
                      TransitionComponent={Fade}
                    >
                      <MenuItem><Link className={style.nav} to="/"><FormattedMessage id="cellar" /></Link></MenuItem>
                      <MenuItem><Link className={style.nav} to="/profil"><FormattedMessage id="profile" /></Link></MenuItem>
                      <MenuItem><Link className={style.nav} to="/catalogue"><FormattedMessage id="catalog" /></Link></MenuItem>
                      <MenuItem><Link className={style.nav} to="/about"><FormattedMessage id="about" /></Link></MenuItem>
                      <MenuItem><Button variant="contained" color="secondary" onClick={handleSignOut} ><FormattedMessage id="logout" /></Button></MenuItem>
                    </Menu>

                </Toolbar>
            </AppBar>
        </div>
    )
}

export default Header

import { Button } from '@material-ui/core'
import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useAuth } from '../contexts/Auth';
import { FormattedMessage } from "react-intl";

import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';

function SignUp(props) {


    const history = useHistory();
    const { signUp } = useAuth();
    const [errorMsg, setErrorMsg] = useState(null);
    const [showPassword, setShowPassword] = useState(false)

    const signUpSchema = Yup.object().shape({
        email: Yup.string().required(<FormattedMessage id="email_required" />).email(<FormattedMessage id="invalid_email" />),
        password: Yup.string().required(<FormattedMessage id="password_required" />).min(6, <FormattedMessage id="password_min" />)
    });

    const handleSubmit = async (values) => {

        
        const { error } = await signUp({
            email: values.email,
            password: values.password,
        })
        if (error) {
            setErrorMsg(<FormattedMessage id="email_exist" />)
            throw error
        }
        history.push("/profil")

    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
    <div className="main">
        <h1><FormattedMessage id="sign_up" /></h1>
            <Formik 
                initialValues={{
                    email: '',
                    password: '',
                }}
                validationSchema={signUpSchema}
                onSubmit={handleSubmit}
            >
            {({submitForm, isSubmitting, handleChange}) => (
                <Form>
                    <Field 
                        component={TextField}
                        name="email"
                        type="email"
                        label="Email"
                        variant="outlined"
                        autoComplete="on"
                        style={{margin: '1em', backgroundColor: '#f6f4e6', width: '275px'}}
                    />
                    <br/>
                    <Field 
                        component={TextField}
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        onChange={handleChange}
                        label={<FormattedMessage id="password_label" />}
                        variant="outlined"
                        autoComplete="password"
                        InputProps={{
                        endAdornment:(
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        )}}
                        style={{backgroundColor: '#f6f4e6'}}
                    />
                    <br/>
                    {isSubmitting}
                    <br/>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                    >
                        <FormattedMessage id="sign_up" />
                    </Button>
                </Form>
            )}
            </Formik>
            <p style={{margin: '5%', color: 'red'}}>{errorMsg}</p>
            <p className="description"><FormattedMessage id="part_of_us" /> <br />
          <Link to='welcome' onClick={props.handleSignUpClick}>Login</Link>
          </p>
        </div>
    )
}

export default SignUp

import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button } from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import ResetPassword from './ResetPassword/ResetPassword';
import { useAuth } from '../contexts/Auth';
import { FormattedMessage } from "react-intl";

import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';

function Login(props) {

    const [loading, setLoading] = useState(false);
    const [showReset, setShowReset] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const { signIn } = useAuth();
    const history = useHistory();

    useEffect(() => {
        return () => {
            setLoading(false);
        };
    }, []);

    const handleLogin = async (values) => {
        try {
            setLoading(true);
            const { error } = await signIn({ email: values.email, password: values.password});
            if (error) throw error
        } catch (error) {
            alert(error.error_description || error.message)
        } finally {
            history.push('/')
            setLoading(false)
            
        }
    }

    function handleClick(){
        setShowReset(!showReset)
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    return (
        <div className="main">
            <h1>Login</h1>
            <Formik initialValues={{email: '', password: ''}} onSubmit={handleLogin}>
            {({submitForm, isSubmitting}) => (
                    <Form>
                        <Field 
                            component={TextField}
                            name="email"
                            type="email"
                            label="Email"
                            aria-label="Email"
                            variant="outlined"
                            autoComplete="on"
                            style={{margin: '1em', backgroundColor: '#f6f4e6', width: '275px'}}
                        />
                        <br/>
                        <Field 
                            component={TextField}
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            label={<FormattedMessage id="password_label" />}
                            aria-label={<FormattedMessage id="password_label" />}
                            variant="outlined"
                            autoComplete="password"
                            style={{backgroundColor: '#f6f4e6'}}
                            InputProps={{
                            endAdornment:(
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )}}
                        />
                        <br/>
                        {isSubmitting}
                        <br/>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            onClick={submitForm}
                            
                        >
                            {loading ? <span><FormattedMessage id="loading" /></span> : <span>Login</span>}
                        </Button>
                    </Form>
                )}
            </Formik>
            <p className="description"><FormattedMessage id="password_forgot" /><br/> 
                <Link to="welcome" onClick={handleClick}><FormattedMessage id="magic_link" /></Link>
                
            </p>
            <div> {showReset ? <ResetPassword /> : null} </div>
            <p className="description"><FormattedMessage id="not_registered" /><br/>
                <Link to="welcome" onClick={props.handleSignUpClick}><FormattedMessage id="sign_up" /></Link>
            </p>
        </div> 
    )
}

export default Login

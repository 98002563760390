import React, { useState} from 'react';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button } from '@material-ui/core'
import { supabase } from '../../lib/supabaseClient';
import * as Yup from 'yup';
import SimpleModal from '../SimpleModal';
import { FormattedMessage } from "react-intl";

function ResetPassword() {

    const [showModal, setShowModal] = useState(false)

    const resetPasswordSchema = Yup.object().shape({
        email: Yup.string().email().required(<FormattedMessage id="email_required" />)
    })

    const resetPassword = async (values) => {

        let { user, error } = await supabase.auth.signIn({
            email: values.email
        })

        console.log(user)

        setShowModal(true)

        if(error) { 
        throw error 
        
        }
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }

    return (
        <>
        <div>
            <Formik initialValues={{email: ''}} validationSchema={resetPasswordSchema} onSubmit={resetPassword}>
            {({submitForm, isSubmitting, errors, touched, isValid, dirty}) => (
                <Form>
                <Field 
                        component={TextField}
                        name="email"
                        type="email"
                        label="Email"
                        required
                        variant="outlined"
                        autoComplete="on"
                        error={!!(touched.email && errors.email)}
                        style={{marginBottom: '1vh', backgroundColor: '#f6f4e6', width: '275px'}}

                    />
                    <br/>
                {isSubmitting}
                <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting && !(isValid && dirty)}
                        onClick={submitForm}
                        
                    >
                        <span><FormattedMessage id="send" /></span>
                    </Button>
                </Form>
            )}
            </Formik>
        </div>
        <SimpleModal title={<FormattedMessage id="email_send" />} bodyTxt={<FormattedMessage id="mailbox_check" />} showModal={showModal} handleCloseModal={handleCloseModal} />
        </>
    )
}

export default ResetPassword

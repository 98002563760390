import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { FormattedMessage } from "react-intl";
import TutoSteps from '../components/TutoSteps/TutoSteps';
import Login from '../components/Login';
import SignUp from '../components/SignUp';
import logo from '../images/macavelogo.svg';


function Welcome() {

    const [isShowingSignUp, setShowingSignUp] = useState(false)

    function handleSignUpClick() {
        setShowingSignUp(!isShowingSignUp)
    }
    return (
        <div className="main">
            <div style={{backgroundColor: '#f6f4e6', display: 'inline-block'}}>
                <img src={logo} alt="logo" style={{width: '20rem', height: '100%', textAlign: 'left', filter: "invert(24%) sepia(11%) saturate(468%) hue-rotate(184deg) brightness(90%) contrast(84%)"}} />
            </div>
            <div>
                <h2><FormattedMessage id="login_title" /></h2>
                <p><FormattedMessage id="first_login_p" /></p>
                <p><FormattedMessage id="second_login_p" /></p>
            </div>
            <Grid container justify="space-around" alignItems="baseline">
                <Grid item style={{margin: '2vw'}}>
                    <TutoSteps />
                </Grid>
                <Grid item>
                    {!isShowingSignUp ? <Login handleSignUpClick={handleSignUpClick} /> : <SignUp handleSignUpClick={handleSignUpClick} />}
                </Grid>
            </Grid>

        </div>
    )
}

export default Welcome

import { LOCALES } from "./locales";

export const messages = {
    [LOCALES.ENGLISH]: {
        mcb: "My Beer Cellar",
        login_title: "Your personal beer cellar!",
        first_login_p: "My beer cellar is the perfect place to keep an eye on your cellar.",
        second_login_p: "Sign up or log in to start the adventure!",
        password_label: "Password",
        password_forgot: "Forgot your password?",
        magic_link: "Connect with MagicLink",
        not_registered: "Not registered yet?",
        loading: "Loading",
        lang_select: "Language",
        sign_up: "Sign Up",
        email_required: "Email required",
        invalid_email: "Invalid Email",
        password_required: "Password required",
        password_min: "6 characters min.",
        email_exist: "A user with this email is already registered",
        part_of_us: "Already part of us?",
        send: "Send",
        email_send: "Email sent",
        mailbox_check: "Check your mailbox and click on the link in it.",
        cellar: "My Cellar",
        profile: "Profile",
        catalog: "Catalog",
        about: "About",
        logout: "Logout",
        username: "Username",
        update: "Update",
        provide_username: "Username is required before starting on the App",
        greeting: "Hello",
        create_cellar: "Create a cellar",
        start_adventure: "Start by creating a cellar to begin your adventure",
        your: "Your",
        is_empty: "is empty",
        valid_temp: "The temperature is not valid, use the '.' rather than ','",
        created_on: "Created on",
        cannot_delete: "You can't delete a cellar with beers in it",
        confirm_delete_cellar: "Are you sure you want to delete this cellar?",
        cancel: "Cancel",
        delete: "Delete",
        sort: "Sort",
        name: "Name",
        brewery: "Brewery",
        country: "Country",
        price: "Price",
        bottled: "Bottled on:",
        expire: "Expire on:",
        qty: "Quantity:",
        unit_price: "Unit price:",
        project: "Project",
        added: "Added on",
        change: "Update",
        diff_beer: "Different beers:",
        total_beers: "Total beers:",
        cellar_value: "Cellar value:",
        search: "Search",
        add: "Add",
        create: "Create",
        name_required: "The name is required",
        sim_brewery: "A similar brewery exist:",
        country_required: "The country is required",
        brewery_not_listed: "The brewery isn't in the list?",
        brewery_required: "The brewery is required",
        style_required: "The style required",
        cont_required: "Container is required",
        vol_required: "Volume is required",
        vol_valid: "Volume is not valid, use the '.' rather than ','",
        tit_valid: "Alc. titration is not valid, use the '.' rather than ','",
        batch_optional: "Batch (Optional)",
        cat_intro: "Here are all the beers registered by the community, if you don't find yours, add it to the catalog before being able to add to your cellar.",
        search_ph: "Name, Brewery, Style, Country",
        add_beer_to_cat: "Add a beer to the catalog",
        latest_add: "Latest additions",
        indicate_cellar: "Please enter your cellar",
        price_valid: "Price is not valid, use the '.' rather than ','",
        date_valid: "The date can't be ulterior to today",
        thanks: "Thanks for your support 💛",
        dl_first: "You can install the app by clicking on ",
        dl_sec: " then Add to Homepage.",
        if_notif: "If you have the notification saying that you can install the app, just click on it.",
        for_any: "For any remarks, suggestions, bug report or just a thank you, send an Email to:",
        dev_looking: "If you are looking for a dev, I am available at the same address",
        support_project: "Want to support the project?",
        new_func: "New functionalities will soon be implemented, including:",
        multiling: "Multilingual support",
        stats: "Advanced statistics",
        social: "A social aspect to exchange with other beer enthusiasts",
        homebrew_sec: "Homebrew section",
        ref_brew: "Better brewery SEO",
        best_suggestions: "Your best suggestions",
        tuto_step1: "Complete your profile",
        tuto_step2: "Create one (or many) cellar(s)",
        tuto_step3: "Add beers to your cellar",
        tuto_step4: "Share the app with your friends",
        back: "back",
        next: "next",
    },
    [LOCALES.FRENCH]: {
        mcb: "Ma Cave à Bières",
        login_title: "Votre cave à bières personnelle !",
        first_login_p: "Ma cave à bières est l'endroit idéal pour garder un oeil sur votre cave.",
        second_login_p: "Inscrivez-vous ou connectez-vous pour commencer l'aventure !",
        password_label: "Mot de Passe",
        password_forgot: "Mot de passe oublié ?",
        magic_link: "Connection avec MagicLink",
        not_registered: "Pas encore inscrit(e)? ",
        loading: "Chargement",
        lang_select: "Langue",
        sign_up: "Inscription",
        email_required: "Merci d'indiquer votre Email",
        invalid_email: "Email invalide",
        password_required: "Merci de renseigner un mot de passe",
        password_min: "6 caractères min.",
        email_exist: "Un utilisateur avec cet Email existe déjà.",
        part_of_us: "Déjà inscrit ?",
        send: "Envoyer",
        email_send: "Email envoyé",
        mailbox_check: "Vérifiez votre boite Mail pour vous connecter",
        cellar: "Ma Cave",
        profile: "Profil",
        catalog: "Catalogue",
        about: "A propos",
        logout: "Déconnect.",
        username: "Prénom",
        update: "Mettre à jour",
        provide_username: "Merci de renseigner votre prénom avant de démarrer l'aventure",
        greeting: "Bonjour",
        create_cellar: "Créer une cave",
        start_adventure: "Commencez par créer une cave pour démarrer l'aventure",
        your: "Votre",
        is_empty: "est vide",
        valid_temp: "La température n'est pas valide, utilisez le '.' plutôt qu'une ','",
        created_on: "Créée le",
        cannot_delete: "Vous ne pouvez pas supprimer une cave contenant des bières",
        confirm_delete_cellar: "Êtes-vous sûr de vouloir supprimer cette cave ?",
        cancel: "Annuler",
        delete: "Supprimer",
        sort: "Trier",
        name: "Nom",
        brewery: "Brasserie",
        country: "Pays",
        price: "Prix",
        bottled: "Embouteillée le:",
        expire: "Expire le:",
        qty: "Quantité:",
        unit_price: "Prix unitaire:",
        project: "Projet",
        added: "Ajoutée le",
        change: "Modifier",
        diff_beer: "Bières différentes:",
        total_beers: "Nombre total de bières:",
        cellar_value: "Valeur de la cave:",
        search: "Rechercher",
        add: "Ajouter",
        create: "Créer",
        name_required: "Le nom est requis",
        sim_brewery: "Une brasserie similaire existe:",
        country_required: "Le pays est requis",
        brewery_not_listed: "La brasserie ne figure pas dans la liste ?",
        brewery_required: "La brasserie est requise",
        style_required: "Le style est requis",
        cont_required: "Le contenant est requis",
        vol_required: "Le volume est requis",
        vol_valid: "Le volume n'est pas valide, utilisez le '.' plutôt qu'une ','",
        tit_valid: "Le titrage n'est pas valide, utilisez le '.' plutôt qu'une ',' ",
        batch_optional: "Batch (Optionnel)",
        cat_intro: "Ici figurent toutes les bières enregistrées par la communauté, si vous ne trouvez pas la votre, ajoutez-la au catalogue avant de pouvoir l'ajouter à votre cave.",
        search_ph: "Nom, Brasserie, Style, Pays",
        add_beer_to_cat: "Ajouter une bière au catalogue",
        latest_add: "Derniers ajouts",
        indicate_cellar: "Merci d'indiquer la cave concernée",
        price_valid: "Le prix n'est pas valide, utilisez le '.' plutôt qu'une ','",
        date_valid: "La date ne peut pas être supérieur à aujourd'hui",
        thanks: "Merci de votre soutien 💛",
        dl_first: "Vous pouvez installer l'app sur votre smartphones en cliquant sur ",
        dl_sec: " puis Ajouter à l'écran d'accueil.",
        if_notif: "Si vous recevez la notification vous proposant d'installer l'app, cliquez dessus.",
        for_any: "Pour toutes remarques, suggestions, bug report ou remerciements, envoyez un mail à :",
        dev_looking: "Si vous recherchez un dev, je suis disponible à la même adresse.",
        support_project: "Envie de supporter le projet ?",
        new_func: "De nouvelles fonctionnalités vont venir implémenter l'application, notamment :",
        multiling: "La prise en charge multilingue",
        stats: "Des statistiques plus avancées",
        social: "Un aspect social pour échanger entre passionnés",
        homebrew_sec: "Section Brassam",
        ref_brew: "Meilleur référencement des brasseries",
        best_suggestions: "Vos meilleures suggestions",
        tuto_step1: "Complètez votre profil",
        tuto_step2: "Créez une (ou plusieurs) cave(s)",
        tuto_step3: "Ajoutez des bières à votre cave",
        tuto_step4: "Partagez l'app avec vos amis",
        back: "retour",
        next: "suivant",
    }
}
import React, {useState, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { unstable_createMuiStrictModeTheme as createMuiTheme, CssBaseline, MuiThemeProvider, CircularProgress } from '@material-ui/core';
import { supabase } from './lib/supabaseClient';
import { IntlProvider } from 'react-intl';
import { LOCALES } from "./i18n/locales";
import { messages } from "./i18n/messages";

import Header from './components/Header/Header';
import { AuthProvider } from './contexts/Auth';
import { PrivateRoute } from './components/PrivateRoute';
import LanguageSwitcher from './components/LanguageSwitcher';
import Welcome from './containers/Welcome';

const Home = lazy(() => import('./containers/Home/Home'));
const Profil = lazy(() => import('./containers/Profil/Profil'));
const About = lazy(() => import('./containers/About/About'));
const Catalogue = lazy(() => import('./containers/Catalogue/Catalogue'));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#fddb3a',
    },
    secondary: {
      main: '#41444b',
    },
    text: {
      primary: '#41444b'
    },
    background: {
      default: '#f6f4e6',
      paper: '#f6f4e6',
    }
  },
  typography: {
    fontFamily: 'Rubik',
  }
});

theme.typography.h3 = {
  fontSize: '2.6rem',
  '@media (min-width:600px)': {
    fontSize: '2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
}



function App() {

  const [session, setSession] = useState(null);

  const locale = LOCALES.FRENCH;

  const [currentLocale, setCurrentLocale] = useState(locale);

  useEffect(() => {
    setSession(supabase.auth.session())

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, [])

  const handleLanguageChange = (e) => {
    setCurrentLocale(e.target.value);
  };

  return (
    <IntlProvider messages={messages[currentLocale]} locale={currentLocale} defaultLocale={LOCALES.FRENCH}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Suspense fallback={<CircularProgress color="primary" style={{marginTop: '50vh'}} />}>
            <AuthProvider>
            {session ? <Header /> : null }
              <Switch>
                <Route path="/welcome"><Welcome /> 
                  {session && <Redirect to="/" />} 
                  <div style={{position: 'relative', bottom: 7, left: 0}}>
                    <LanguageSwitcher currentLocale={currentLocale} handleLanguageChange={handleLanguageChange} />
                  </div>
                </Route>
                <Route exact path="/"><Redirect to="/home"/></Route>
              
                <PrivateRoute path="/home" comp={Home}/>
                <PrivateRoute path="/about" comp={About} />
                <PrivateRoute path="/profil" comp={Profil} handleLanguageChange={handleLanguageChange} currentLocale={currentLocale} />
                <PrivateRoute path="/catalogue" comp={Catalogue} />
              </Switch>
            </AuthProvider>
          </Suspense>
        </Router>
      </MuiThemeProvider>
    </IntlProvider>
  );
}



export default App;

import React from 'react';
import  {Button, Modal} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
      paddingTop: '50px',
      marginTop: '1vh',
      marginBottom: '1vh'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

const SimpleModal = (props) => {

    const classes = useStyles();

    return (
        
    <Modal className={classes.modal} open={props.showModal} onClose={props.handleCloseModal}>
        <div className={classes.paper}>
          <h1>{props.title}</h1>
          <p>{props.bodyTxt}</p>
          <Button variant='contained' color="primary" onClick={props.handleCloseModal}>
              OK
          </Button>
        </div>
    </Modal>

    
    )
}

export default SimpleModal;
import React from 'react';
import { FormattedMessage } from "react-intl";
import { LOCALES } from '../i18n/locales';
import { FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';

function LanguageSwitcher(props) {

    const languages = [
        { name: "Français", code: LOCALES.FRENCH, emoji: "🇫🇷" },
        { name: "English", code: LOCALES.ENGLISH, emoji: "🇬🇧" },
      ];
    return (
        <div>
            <FormControl>
                <InputLabel id="select-label"><FormattedMessage id="lang_select" /></InputLabel>
                <Select
                labelId="select-label"
                id="lang-select"
                value={props.currentlocale || ''}
                onChange={props.handleLanguageChange}
                style={{width: '100px'}}
                placeholder={props.currentlocale}
                >
                    {languages.map(({name, code, emoji}) => (
                        <MenuItem key={code} value={code}> {emoji} {name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}

export default LanguageSwitcher
